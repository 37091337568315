<template>
  <VueFinalModal
    class="flex flex-col items-center justify-center h-full !z-[99999999]"
  >
    <div v-if="title" class="px-4 py-6 text-center">
      <h4
        class="text-xl font-semibold text-gray-600 uppercase"
        v-text="title"
      />
    </div>
    <div :class="modalBodyClass" class="text-base rounded-lg shadow-lg">
      <slot />
    </div>
    <div
      v-if="hasSlot('footer')"
      class="p-4 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
    >
      <slot name="footer" />
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal } from "vue-final-modal";

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  modalBodyClass: {
    type: [Array, String],
    default: "overflow-y-scroll px-4 py-2 max-w-xl",
  },
});

const slots = useSlots();

function hasSlot(name = "default") {
  return !!slots[name] || !!slots.default[name];
}
</script>

<style lang="postcss">
.vfm__overlay {
  @apply bg-gray-500 bg-opacity-60;
}

.vfm__content {
  @apply w-full items-center justify-center flex;
}
</style>
